.btn-list{
    font-size: 1.5rem !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 5px !important;
    border: none !important;
    background: none !important;
    /* position: relative !important; */
    margin-left: 20px;
    color: #333 !important;
    margin-bottom: 1.5rem;
    position: fixed !important;
    top: 18%;
    left: 0;
    /* right: 2rem; */
    /* height: 3rem; */
    z-index: 2;
    box-shadow: 0 0.13333rem 0.4rem 0 rgb(0, 0, 0) !important;
}

.list-number{
    background-color: red;
    width: 15px;
    height: 15px;
    position: absolute;
    top: 0;
    left: 0;
    color: white;
    font-size: 11px;
    font-weight: 600;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}