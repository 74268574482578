.cart-item {
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px;
    margin-bottom: 20px;
    position: relative;
}

.cart-item-image {
    max-width: 60px;
    max-height: 80px;
}

.cart-item-title {
    font-size: 0.85rem;
    font-weight: 500;
    color: black;
    margin-bottom: 8px;
}

.cart-item-price {
    font-size: 17px;
    font-weight: 500;
}

.cart-item-size {
    font-size: 12px;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
}

.cart-item-content {
    padding: 0 35px 0 10px;
}

.button__remove-item {
    position: absolute;
    top: 0;
    right: 0;
    color: #d83232;
    font-size: 1.4rem;
    border: none;
    background: none;
    cursor: pointer;
}