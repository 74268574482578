.pointer {
    cursor: pointer;
}

.mt {
    padding-top: 5rem;
}

.padding-bottom{
    padding-bottom: 3rem;
}

.margin-b{
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
}

.sombra{
    margin-top: 10px !important;
    padding-top: 0;
}