.container-producs {
    max-width: 1100px;
    margin: 0 auto;
}

.products {
     padding: 10px 20px 50px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
}

.container-producs2 {
    max-width: 1100px;
    margin: 0 auto;
}

.products2 {
     padding: 50px 20px 0px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
}