.cart {
    width: 100%;
    max-width: 330px;
    background-color: aliceblue;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    padding: 55px 20px 20px; 
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 1;
    transform: translate(110%, 0);
    transition: all 400ms ease;
}

.fechar {
    top: 0;
    right: 0;
    margin: 30px 230px;
    padding: 0px 0px 0px !important; 
    font-size: 1.2rem;
    /* cursor: pointer; */
}

.btn-fechar{
    border: none;
    background: none;
}

.cart-items{
    flex-grow: 1;
    overflow: auto;
}

.cart-resume {
    font-size: 1.2rem;
    font-weight: 500;
    padding: 20px 10px;
    border-top: 1px solid #ddd;
}

.cart-items .cart-item:last-child {
    border-bottom: none;
}

.cart--active {
    transform: translate(0, 0);
}

.margin-bt {
    margin-bottom: 1rem !important;
}