.card__infos {
    padding: 20px;
    border-top: 1px solid #ddd;
}

.card__price {
    font-size: 20px;
    font-weight: 400;
    display: block;
    margin-bottom: 10px;
}

.card__title {
    font-size: 15px;
    color: #000;
    line-height: 1.5;
    font-weight: 500;
    text-overflow: ellipsis;
}

.product-card{
    width: 100%;
    max-width: 300px;
    /* max-height: 355px; */
    background-color: #c4c4c4;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    margin: 0 auto;
    position: relative;
    overflow-wrap: break-word;
}

.card__image {
    width: auto;
    height: auto;
    max-width: 350px;
    /* max-height: 350px; */
}

.card__image2 {
    width: 100%;
    height: 100%;
    max-width: 800px;
    max-height: 650px;
}

.button__add-cart {
    position: absolute;
    top: 0;
    right: 0;
    width: 45px;
    height: 45px;
    margin: 12px 15px;
    color: #0c5dd6;
    /* display: none; */
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 50px;
    border-color: rgba(255, 255, 255, 0.8);
    font-size: 1.5rem;
    cursor: pointer;
}

.button__add-cart2 {
    position: absolute;
    top: 0px;
    right: 0;
    width: 30px;
    height: 30px;
    margin: 12px 15px;
    color: #0c5dd6;
    /* display: none; */
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 50px;
    border-color: rgba(255, 255, 255, 0.8);
    font-size: 1.0rem;
    cursor: pointer;
}

.product-card:hover {
    box-shadow: 0 2px 10px rgba(68, 68, 68, 0.473);
}

.product-card:hover .button__add-cart {
    display: flex;
}