.cart-item {
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px;
    margin-bottom: 20px;
    position: relative;
    max-width: 200px;
    overflow-wrap: break-word;
}

.cart-item-image {
    max-width: 60px;
    max-height: 80px;
}

.cart-item-title {
    font-size: 0.85rem;
    font-weight: 500;
    color: black;
    margin-bottom: 8px;
    text-overflow: ellipsis;
}

.cart-item-price {
    font-size: 17px;
    font-weight: 500;
}

.cart-item-info {
    font-size: 14px;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
}

.cart-item-content {
    max-width: 190px !important;
    padding: 0 35px 0 10px;
}

.items {
   /* padding: 120px 20px 50px; */
   display: grid;
   grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
   gap: 20px;
}