@font-face {
    font-family: noir;
    src: local('noir'), url(../fonts/N_E_B_B.TTF) format('truetype');
}
@font-face {
    font-family: lato;
    src: local('lato'), url(../fonts/Lato-Light.ttf) format('truetype');
}

.menu-bg{
    background-color: rgb(255, 255, 255);
    /* border-bottom: 1px solid red; */
    box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 5px;
}

.bg-body-tertiary{
    background-color: rgb(255, 255, 255) !important;
    box-shadow: none !important;
}

.tp-font{
    /* font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */
    font-family: lato, sans-serif !important;
    font-size: 1.0rem !important;
    color: rgb(0, 0, 0) !important;
}

.dropdown-toggle{
    font-family: lato, sans-serif !important;
    font-size: 1.0rem !important;
    color: rgb(0, 0, 0) !important;
}

.navbar-toggler-icon{
    background-image: var(--mdb-navbar-toggler-icon-bg) !important; 
}

.bg-ex{
    background-color: rgba(207, 207, 207, 0.459);
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
    transition: background-color 1s ease-out;
}

.bg-ex:hover {
    background-color: rgba(80, 79, 79, 0.658);
}

.ex-button{
    font-family: noir, sans-serif;
    text-decoration: none;
    font-size: 1rem;
}