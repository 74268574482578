.product-image {
    width: auto;
    max-width: 350px !important;
    height: auto;
    max-height: 300px;
    margin-bottom: 1rem;
    /* min-height: 300px !important;
    min-width: 300px !important; */
}

.modal-content {
    box-shadow:
    rgba(0, 0, 0, 0.2) 2px 2px 2px 2px,
    rgba(0, 0, 0, 0.14) 2px 2px 2px 2px,
    rgba(0, 0, 0, 0.12) 2px 2px 3px 2px,
    rgba(0, 0, 0, 0.12) 2px 2px 3px 2px;
}

.asdasdad{
    background-color: rgb(25, 45, 63) !important;
}