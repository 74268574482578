.center h1{
    color: #000000;
    align-items: center;
    font-family: lato, sans-serif !important;
}

.center{
    margin-top: 2rem;
    align-items: center !important;
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center !important;
}

.whats {
    max-width: 80px;
    max-height: 80px;
    margin-bottom: 0.7rem;
}

.insta {
    max-width: 48px;
    max-height: 48px;
    margin-bottom: 0.7rem;
}

.email {
    max-width: 40px;
    max-height: 40px;
    margin-bottom: 0.7rem;
}

.space {
    margin-right: 5rem;
}

.nome {
    font-weight: bolder !important;
    color: #27b333;
}

.nome-insta {
    font-weight: bolder !important;
    color: #7416a0;
}

.nome-email {
    font-weight: bolder !important;
    color: #1172cc;
}

.info{
    margin-top: 2rem;
    font-weight: bolder !important;
    color: #000;
}