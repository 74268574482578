@font-face {
    font-family: lato;
    src: local('lato'), url(../../fonts/Lato-Light.ttf) format('truetype');
}

.bg-legenda{
    background-color: rgba(0, 0, 0, 0.377);
}

.legenda{
    font-family: lato, sans-serif;
}

.mb{
    margin-bottom: 3rem;
}

.ml{
    margin-left: 1rem;
}

.hidden{
    overflow: hidden !important;
}

.zoom-in{
    transition: transform 0.3s ease-in-out !important;
}

.zoom-in:hover{
    transform: scale(1.2) !important;
}

.text1{
    font-family: lato, sans-serif !important;
    font-weight: bold !important;
    font-size: 1.4rem !important;
    margin-bottom: 5px !important;
}

.text2{
    font-family: lato, sans-serif !important;
    font-weight: bold !important;
    font-size: 1rem !important;
    text-align: center;
}

.logo-space{
    margin-top: 14px !important;
}

.padding-b{
    padding-bottom: 10.5rem;
}