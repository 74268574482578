.radius {
    border-radius: 5px;
}

.sombra {
    box-shadow:
        rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
        rgba(0, 0, 0, 0.14) 0px 1px 1px 0px,
        rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
}

.teste {
    /* width: 100%; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.bg-login {
    /* background-image: url("../../../public/images/banner03.jpg");
    background-repeat: no-repeat;
    background-size: cover; */
    background-color: rgb(146, 146, 146) !important;
}