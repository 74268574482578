@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");
@import "@bitnoi.se/react-scheduler/dist/style.css";

/* .nav-item.dropdown:hover .dropdown-menu {
  display: block;
} */

html,
body,
#root,
.app,
.content {
  font-family: "Roboto", sans-serif ;
  position: relative;
  min-height: 100%;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "PT Sans", sans-serif;
}

.home,
.consulting,
.contactus,
.design,
.development,
.marketing,
.services,
.signup {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  font-size: 4rem;
  background-position: center;
  background-repeat: none;
  background-size: cover;
  text-shadow: 4px 4px 4px #fff;
}


.position{
  position: relative !important;
  min-height: 100vh;
}