.MuiDataGrid-columnHeaderTitle {
    color: white;
}

.MuiDataGrid-virtualScroller {
    color: rgb(0, 0, 0) !important;
}

.MuiDataGrid-columnHeaders {
    background-color: black !important;
}

.MuiDataGrid-footerContainer {
    background-color: black !important;
}

.MuiTablePagination-root{
    color: white !important;
}

.MuiTablePagination-root svg{
    color: white;
}

.MuiDataGrid-toolbarContainer span{
    color: black !important;
}

.MuiButton-text {
    color: black !important;
}

.MuiButtonBase-root{
    color: white ;
}

.MuiSvgIcon-root {
    color: rgb(114, 114, 114) ;
}

.sombra{
    box-shadow:
    rgba(0, 0, 0, 0.2) 1px 2px 1px -1px,
    rgba(0, 0, 0, 0.14) 1px 1px 1px 0px,
    rgba(0, 0, 0, 0.12) 1px 1px 3px 0px,
    rgba(0, 0, 0, 0.12) 1px 1px 3px 2px;
  }

.cart-price {
    font-size: 1.2rem;
    font-weight: 500;
    padding: 20px 10px;
}